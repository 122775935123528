import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  RefObject,
  SetStateAction,
} from "react";
import { NavigateFunction } from "react-router";
import { RootState } from "../store";
import { UnknownAction } from "@reduxjs/toolkit";
import { UserState } from "./state";
import { Country, Organisation } from "./db";
import { NavigationAction } from "#constants/navigation";
import { DehydratedState, QueryClient } from "@tanstack/react-query";
import { TranslationKey } from "#customTypes/translation";

export type WindowSize = {
  width: number;
  height: number;
};

export type PageProps = {
  translations: Record<string, string>;
  currentLocale: string;
  cookies?: Cookies | undefined;
  acceptedLocales: {
    code: string;
    region: string | null;
    quality: number;
  }[];
  isMobile: boolean;
  wlaOrganisation: Organisation | undefined;
};

type UrlParsed = {
  origin: null | string;
  pathname: string;
  pathnameOriginal: string;
  search: Record<string, string>;
  searchAll: Record<string, string[]>;
  searchOriginal: null | string;
  hash: string;
  hashOriginal: null | string;
};

export type PageContext = {
  Page: (pageProps: PageProps) => ReactElement;
  pageProps: PageProps;
  urlPathname: string;
  fullUrl: string;
  initialStoreState?: RootState;
  serverQueryClient: QueryClient;
  dehydratedState: DehydratedState;
  urlOriginal: string;
  redirectTo?: string;
  documentProps?: {
    title?: string;
    description?: string;
  };
  urlParsed: UrlParsed;
};

export type BriefAnswers = {
  collection: {
    optionid: number;
    value: string;
  }[];
  pollitemid: string;
}[];

export type BriefResponse = {
  briefId: string;
  responses: BriefAnswers;
  trackingId?: string;
  trackingCallback?: string;
};

export type ProfileDetailsForm = {
  birthday: string;
  email: string;
  gender: string;
  countryId: number;
  displayName: string;
  countryName: string | undefined;
};

export interface AuthenticatedUser {
  auth_secret: string;
  deviceref: string;
  magiclink: string;
  userref: string;
  redirect?: string;
  component?: string;
}

export type APIResponse<T> = {
  status: string | number;
  code: number | undefined;
  data: T;
};

export type APIError = {
  error_details?: string;
  error_type?: string;
};

export type ScreenerSuccessViewProps<T> = {
  useGender: [string, React.Dispatch<React.SetStateAction<string>>];
  useEmail: [string, React.Dispatch<React.SetStateAction<string>>];
  useDisplayName: [string, React.Dispatch<React.SetStateAction<string>>];
  useCountryId: [string, Dispatch<SetStateAction<string>>];
  useBirthday: [string, Dispatch<SetStateAction<string>>];
  useAPIError: [
    APIResponse<T> | undefined,
    Dispatch<SetStateAction<APIResponse<T> | undefined>>
  ];
  countries: Country[];
  onSubmit: (profileDetails: {
    birthday: string;
    gender: string;
    displayName: string;
    countryName: string | undefined;
    countryId: number;
    email: string;
  }) => Promise<void>;
  isUserLoggedIn: boolean;
  hasCompletedProfile: boolean;
  isSubmitLoading: boolean;
};

export type VerifiedUserResponse = {
  verified: boolean;
};

export type Cookies = {
  user: string;
};

export type RGBA = {
  R: number;
  G: number;
  B: number;
  A: number;
};

export type UseModalProps = {
  open?: boolean;
  Content?: ReactNode | null;
};

export type ItemComponentProps = {
  preventScroll: boolean;
};

export type ItemComponent = {
  id: string;
  ItemComponent: (props: ItemComponentProps) => JSX.Element;
};

export type SwitchItemComponent = ItemComponent & { ref: RefObject<HTMLDivElement> };

export type BriefContentBody = {
  args: {
    briefref: string;
  };
  app_id: string;
};

export type NavigationItemLabelColor = "primary" | "secondary";

export type NavigationItemProps = {
  id: NavigationAction;
  LogoActive: React.FC<React.SVGProps<SVGSVGElement>>;
  LogoInactive: React.FC<React.SVGProps<SVGSVGElement>>;
  ActionIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  name: TranslationKey;
  label?: TranslationKey;
  labelColor?: NavigationItemLabelColor;
};

export type NavigationItems = NavigationItemProps[];

export type FeedSearchFilters = {
  channelId?: NavigationAction | number | null | undefined;
  onlyPrivate?: boolean;
};

export type UseScrollChannels = [number, React.Dispatch<React.SetStateAction<number>>];

export type ScrollDirection = "up" | "down";

export type OnSubmitProfileFormProps = {
  profile: ProfileDetailsForm;
  dispatch: Dispatch<UnknownAction>;
  user: UserState;
  navigate: NavigateFunction;
  fingerprintId: string;
  briefRef: string;
  urlSearchParams: URLSearchParams;
  hasCompletedProfile: boolean;
};

export enum IMAGE_TYPES {
  BRIEF_BANNER = "briefs_banner_image",
  COVER_PHOTO = "briefs_cover_photo",
  AD_IMAGE = "briefs_ad_image",
  POLL_ITEM = "pollitem_image",
  CHANNEL_LOGO = "brand_logo",
  USER_AVATAR = "user_avatar",
  CHANNEL_BANNER = "brand_theme_banner",
  ORGANISATION_LOGO = "organisation_logo",
}

export enum VIDEO_TYPES {
  VIDEO_COVER = "briefs_cover_video_mobile",
}

export type FetchFeedProps = {
  onlyPrivate?: boolean;
  channelRef?: string;
  start?: number;
  pageSize?: number;
  cookies?: Cookies;
  organisationId?: number;
};

export enum BriefStatus {
  active = "active",
  completed = "completed",
  expired = "expired",
  scheduled = "scheduled",
  closed = "closed",
}

export type PresignedUrlData = {
  url: string;
  fields: Record<string, string>;
  needsSaving: boolean;
};

export type CreateOrganisationRequest = {
  name: string;
  logo: File;
  website: string;
  teamMembers?: string[];
  migratePersonalChannel?: boolean;
};

export type CreateOrganisationResponse = {
  organisationId: number;
  name: string;
  logo: string;
};

export type UpdateOrganisationRequest = {
  organisationId: number;
  name: string;
  logo?: File;
  website: string;
  primaryColor: string;
};

export type DeleteOrganisationRequest = {
  organisationId: number;
};

export type RequestWLARequest = {
  organisationId: number;
};

export enum OrganisationPositionType {
  Owner = 1,
  Admin = 2,
  Researcher = 3,
}

export enum OrganisationStatus {
  VERIFIED = "VERIFIED",
  REJECTED = "REJECTED",
  PENDING_VERIFICATION = "PENDING_VERIFICATION",
  PENDING_DELETION = "PENDING_DELETION",
}

export type MyOrganisationPosition = {
  id: OrganisationPositionType;
  organisation: {
    id: number;
    name: string;
    status: OrganisationStatus;
    domain: string | null;
    logo: string;
    isPersonalOrganisation?: boolean;
  };
};

export enum OrganisationPositionStatus {
  Invited,
  Registered,
}

export type OrganisationPosition = {
  position: OrganisationPositionType;
  user: {
    id: number;
    email: string;
    displayName?: string;
    avatar?: string;
    invitedDate?: string;
    lastConnection?: string;
    status?: OrganisationPositionStatus;
  };
};

export type GetMyOrganisationPositionsResponse = MyOrganisationPosition[];

export type GetTeamMembersResponse = {
  data: {
    organisationPositions: {
      id: OrganisationPositionType;
      name: string;
    };
    users: {
      id: number;
      email: string;
      displayName?: string;
      avatar?: string;
      invitedDate?: string;
      status?: OrganisationPositionStatus;
    };
  }[];
};

export type InviteTeamMembersRequest = {
  organisationId: number;
  emails: string[];
  organisationPositionId: OrganisationPositionType;
};

export type InviteTeamMembersResponse = {
  errors: { email: string; error: string }[];
};

export type AssignPositionRequest = {
  organisationId: number;
  organisationPositionId: OrganisationPositionType;
  email: string;
};

export type ResendInviteRequest = {
  organisationId: number;
  email: string;
};

export type RemoveTeamMemberRequest = {
  organisationId: number;
  email: string;
};
