import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import env from "#env";
import createApi from "../../../createApi";

import {
  ChannelDetails,
  ChannelInviteRequest,
  ChannelListItem,
  CreateChannelRequest,
  CreateChannelResponse,
  DeleteChannelRequest,
  GetChannelByRefRequest,
  GetMyOwnedChannelsRequest,
  GetMySuggestedChannelsRequest,
  UpdateChannelHeader,
  UpdateChannelSettings,
  ReportChannelRequest,
  GetMySuggestedChannelsResponse,
} from "../../types/channel";
import generatePresignedUrl, {
  PresignedUrlType,
} from "#apis/upload/generatePresignedUrl";
import uploadPresignedFile from "#apis/upload/uploadPresignedFile";
import { getHeaders } from "#shared/components/api/utils";
import { QUERY_TAGS } from "#constants/query";
import { getErrorMessage } from "#utils/serviceHelpers";
import {
  GetChannelAdminBriefsRequest,
  GetChannelAdminBriefsResponse,
  GetScreenerBriefsResponse,
} from "#customTypes/brief";
import { PaginatedResponse } from "#customTypes/pagination";
import { fetchAllPages } from "#utils/fetchAllPages";
import { appBaseQuery } from "#features/common/baseQuery";

export const channelsApi = createApi({
  reducerPath: "channels",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/channels/`,
  }),
  tagTypes: [
    QUERY_TAGS.Channel,
    QUERY_TAGS.MyOwnedChannels,
    QUERY_TAGS.MyFollowedChannels,
    QUERY_TAGS.SuggestedChannels,
    QUERY_TAGS.ScreenerBriefs,
    QUERY_TAGS.ChannelAdminBriefs,
  ],
  endpoints: (builder) => ({
    fetchChannelByRef: builder.query<ChannelDetails, GetChannelByRefRequest>({
      query: ({ channelRef, cookies, organisationId }) => ({
        url: `${channelRef}`,
        headers: {
          ...getHeaders(cookies),
          WLA: organisationId ? organisationId.toString() : undefined,
        },
        credentials: "include",
      }),
      serializeQueryArgs: ({ endpointName, queryArgs: { channelRef } }) =>
        JSON.stringify({ endpointName, channelRef }),
      providesTags: (_result, _error, { channelRef }) => [
        { type: QUERY_TAGS.Channel, id: channelRef },
      ],
    }),
    fetchMyOwnedChannels: builder.query<ChannelListItem[], GetMyOwnedChannelsRequest>({
      queryFn: async (
        { cookie, organisationId },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        try {
          const channels = await fetchAllPages({
            queryFn: async (params) => {
              const response = await fetchWithBQ({
                url: "my-owned-channels",
                params,
                method: "GET",
                headers: {
                  ...getHeaders(cookie),
                  WLA: organisationId ? organisationId.toString() : undefined,
                },
                credentials: "include",
              });

              if (response.error) {
                throw response.error;
              }

              return response.data as PaginatedResponse<ChannelListItem>;
            },
          });

          return { data: channels || [] };
        } catch (error) {
          console.error(error);
          return { data: [] };
        }
      },
      serializeQueryArgs: () => QUERY_TAGS.MyOwnedChannels,
      providesTags: [QUERY_TAGS.MyOwnedChannels],
    }),
    fetchSuggestedChannels: builder.query<
      GetMySuggestedChannelsResponse,
      GetMySuggestedChannelsRequest
    >({
      queryFn: async ({ organisationId }, _queryApi, _extraOptions, fetchWithBQ) => {
        const response = await fetchWithBQ({
          url: "suggested-channels",
          method: "GET",
          credentials: "include",
          headers: { WLA: organisationId ? organisationId.toString() : undefined },
        });

        if (response.error) {
          return { error: response.error as FetchBaseQueryError };
        }

        return { data: response.data as GetMySuggestedChannelsResponse };
      },
      serializeQueryArgs: () => QUERY_TAGS.SuggestedChannels,
      providesTags: [QUERY_TAGS.SuggestedChannels],
      keepUnusedDataFor: 0,
    }),
    fetchScreenerBriefs: builder.query<GetScreenerBriefsResponse, string>({
      query: (channelRef) => ({
        url: "screener-briefs",
        method: "GET",
        params: {
          channelRef,
        },
        credentials: "include",
      }),
      providesTags: [QUERY_TAGS.ScreenerBriefs],
    }),
    createChannel: builder.mutation<CreateChannelResponse, CreateChannelRequest>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { logo, banner, ...body } = args;

        const response = await fetchWithBQ({
          url: "/create",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
          credentials: "include",
        });

        const channelRef = (response.data as CreateChannelResponse)?.channelRef;

        if (!channelRef) {
          const errorMessage = getErrorMessage(response.error?.data);
          throw new Error(errorMessage);
        }

        let logoResponse = null;
        let bannerResponse = null;

        if (logo) {
          logoResponse = await generatePresignedUrl({
            type: PresignedUrlType.ChannelLogo,
            body: { channelRef },
          });

          if (!logoResponse) {
            throw new Error("No presigned URL received");
          }

          await uploadPresignedFile({ data: logoResponse, file: logo });
        }

        if (banner) {
          bannerResponse = await generatePresignedUrl({
            type: PresignedUrlType.ChannelBanner,
            body: { channelRef },
          });

          if (!bannerResponse) {
            throw new Error("No presigned URL received");
          }

          await uploadPresignedFile({ data: bannerResponse, file: banner });
        }

        await fetchWithBQ({
          url: `/header/${channelRef}`,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            ...body,
            saveLogo: !!logoResponse?.needsSaving,
            saveBanner: !!bannerResponse?.needsSaving,
          },
          credentials: "include",
        });

        return response.data
          ? { data: response.data as CreateChannelResponse }
          : { error: response.error as FetchBaseQueryError };
      },
      invalidatesTags: [QUERY_TAGS.MyOwnedChannels],
    }),
    updateChannelHeader: builder.mutation<void, UpdateChannelHeader>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { channelRef, logo, banner, ...body } = args;

        let logoResponse = null;
        let bannerResponse = null;

        if (logo) {
          logoResponse = await generatePresignedUrl({
            type: PresignedUrlType.ChannelLogo,
            body: { channelRef },
          });

          if (!logoResponse) {
            throw new Error("No presigned URL received");
          }

          await uploadPresignedFile({ data: logoResponse, file: logo });
        }

        if (banner) {
          bannerResponse = await generatePresignedUrl({
            type: PresignedUrlType.ChannelBanner,
            body: { channelRef },
          });

          if (!bannerResponse) {
            throw new Error("No presigned URL received");
          }

          await uploadPresignedFile({ data: bannerResponse, file: banner });
        }

        const response = await fetchWithBQ({
          url: `/header/${channelRef}`,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...body,
            saveLogo: !!logoResponse?.needsSaving,
            saveBanner: !!bannerResponse?.needsSaving,
          }),
          credentials: "include",
        });

        return response.data
          ? { data: undefined }
          : { error: response.error as FetchBaseQueryError };
      },
      invalidatesTags: (_result, _error, { channelRef }) => [
        { type: QUERY_TAGS.Channel, ref: channelRef },
        { type: QUERY_TAGS.MyOwnedChannels },
      ],
    }),
    updateChannelSettings: builder.mutation<void, UpdateChannelSettings>({
      query: ({ channelRef, ...body }) => ({
        url: `/main/${channelRef}`,
        method: "PUT",
        body,
        credentials: "include",
      }),
      invalidatesTags: (_result, _error, { channelRef }) => [
        { type: QUERY_TAGS.Channel, ref: channelRef },
        { type: QUERY_TAGS.MyOwnedChannels },
      ],
    }),
    inviteChannelMembers: builder.mutation<void, ChannelInviteRequest>({
      query: (body) => ({
        url: `invite`,
        method: "POST",
        body,
        credentials: "include",
      }),
    }),
    requestPublicChannel: builder.mutation<void, DeleteChannelRequest>({
      query: (body) => ({
        url: `request-public-channel`,
        method: "POST",
        body,
        credentials: "include",
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        dispatch(
          updateChannel(args.channelRef, {
            isPublicRequested: true,
          })
        );
      },
    }),
    deleteChannel: builder.mutation<void, DeleteChannelRequest>({
      query: (body) => ({
        url: `delete-channel`,
        method: "POST",
        body,
        credentials: "include",
      }),
      invalidatesTags: (_result, _error, { channelRef }) => [
        { type: QUERY_TAGS.MyOwnedChannels },
        { type: QUERY_TAGS.Channel, ref: channelRef },
      ],
    }),
    fetchChannelAdminBriefs: builder.query<
      GetChannelAdminBriefsResponse,
      GetChannelAdminBriefsRequest
    >({
      query: (params) => ({
        url: `briefs-admin`,
        method: "GET",
        params,
        credentials: "include",
      }),
      providesTags: [QUERY_TAGS.ChannelAdminBriefs],
    }),
    reportChannel: builder.mutation<void, ReportChannelRequest>({
      query: (body) => ({
        url: `report`,
        method: "POST",
        body,
        credentials: "include",
      }),
    }),
  }),
});

const updateChannel = (channelRef: string, updatedFields: Partial<ChannelDetails>) =>
  channelsApi.util.updateQueryData(
    "fetchChannelByRef",
    { channelRef },
    (channel: ChannelDetails) => ({
      ...channel,
      ...updatedFields,
    })
  );

export const {
  useFetchChannelByRefQuery,
  useFetchMyOwnedChannelsQuery,
  useLazyFetchMyOwnedChannelsQuery,
  useFetchSuggestedChannelsQuery,
  useFetchScreenerBriefsQuery,
  useCreateChannelMutation,
  useUpdateChannelHeaderMutation,
  useUpdateChannelSettingsMutation,
  useInviteChannelMembersMutation,
  useRequestPublicChannelMutation,
  useDeleteChannelMutation,
  useFetchChannelAdminBriefsQuery,
  useReportChannelMutation,
} = channelsApi;
