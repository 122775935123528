import { configureStore, StateFromReducersMapObject } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import user from "#features/user/userSlice";
import screenerSubmission from "#features/screenerSubmission/screenerSubmissionSlice";
import action from "#features/action/actionSlice";
import locale from "#features/locale/localeSlice";
import errors from "#features/errors/errorsSlice";
import userEngagement from "#features/userEngagement/userEngagementSlice";
import isDownloadModalOpen from "#features/downloadModal/downloadModalSlice";
import joinBriefId from "#features/joinBriefId/joinBriefIdSlice";
import selectedBriefRef from "#features/selectedBriefRef/selectedBriefRefSlice";
import businessSettings from "#features/businessSettings/businessSettingsSlice";
import toast from "#features/toast/toastSlice";
import navigation from "#features/navigation/navigationSlice";
import godView from "#features/superAdmin/godViewSlice";
import userSettings from "#features/userSettings/userSettingsSlice";
import { oneTimeUseTokenApi } from "#features/oneTimeUseToken/oneTimeUseTokenAPI";
import { feedApi } from "#features/feed/feedAPI";
import { wlaOrganisationApi } from "#features/wlaOrganisation/wlaOrganisationSlice";
import { commonApi } from "#features/common/commonSlice";
import { organisationsApi } from "#features/organisation/organisationAPI";
import { organisationPositionsApi } from "#features/organisationPositions/organisationPositionsAPI";
import { channelsApi } from "#features/channel/channelsAPI";
import { interestsApi } from "#features/interests/interestsAPI";
import { userInterestsApi } from "#features/userInterests/userInterestsAPI";
import { channelInviteTokensApi } from "#features/channelInviteTokens/channelInviteTokensAPI";
import { emailFilesApi } from "#features/emailFiles/emailFilesAPI";
import { oauthApi } from "#features/oauth/oauthAPI";
import { hubspotApi } from "#features/oauth/hubspotAPI";
import { oauthImportApi } from "#features/oauth/oauthImportAPI";
import { channelImportsApi } from "#features/channel/channelImportsAPI";
import { salesforceApi } from "#features/oauth/salesforceAPI";
import { mailchimpApi } from "#features/oauth/mailchimpAPI";
import { briefBuilderApi } from "#features/briefBuilder/briefBuilderAPI";
import { wallpapersApi } from "#features/wallpapers/wallpapersAPI";
import { userCustomPropertiesApi } from "#features/userCustomProperties/userCustomPropertiesAPI";
import { tiptapApi } from "#features/tiptap/tiptapAPI";

const reducers = {
  user,
  screenerSubmission,
  userEngagement,
  action,
  locale,
  isDownloadModalOpen,
  selectedBriefRef,
  joinBriefId,
  errors,
  businessSettings,
  toast,
  navigation,
  godView,
  userSettings,
  [feedApi.reducerPath]: feedApi.reducer,
  [oneTimeUseTokenApi.reducerPath]: oneTimeUseTokenApi.reducer,
  [wlaOrganisationApi.reducerPath]: wlaOrganisationApi.reducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [organisationsApi.reducerPath]: organisationsApi.reducer,
  [organisationPositionsApi.reducerPath]: organisationPositionsApi.reducer,
  [channelsApi.reducerPath]: channelsApi.reducer,
  [interestsApi.reducerPath]: interestsApi.reducer,
  [userInterestsApi.reducerPath]: userInterestsApi.reducer,
  [channelInviteTokensApi.reducerPath]: channelInviteTokensApi.reducer,
  [emailFilesApi.reducerPath]: emailFilesApi.reducer,
  [oauthApi.reducerPath]: oauthApi.reducer,
  [oauthImportApi.reducerPath]: oauthImportApi.reducer,
  [hubspotApi.reducerPath]: hubspotApi.reducer,
  [channelImportsApi.reducerPath]: channelImportsApi.reducer,
  [salesforceApi.reducerPath]: salesforceApi.reducer,
  [mailchimpApi.reducerPath]: mailchimpApi.reducer,
  [briefBuilderApi.reducerPath]: briefBuilderApi.reducer,
  [wallpapersApi.reducerPath]: wallpapersApi.reducer,
  [userCustomPropertiesApi.reducerPath]: userCustomPropertiesApi.reducer,
  [tiptapApi.reducerPath]: tiptapApi.reducer,
};

export type Store = ReturnType<typeof getStore>;
export type RootState = StateFromReducersMapObject<typeof reducers>;

function getStore(PRELOADED_STATE?: RootState) {
  return configureStore({
    reducer: reducers,
    preloadedState: PRELOADED_STATE,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        feedApi.middleware,
        oneTimeUseTokenApi.middleware,
        wlaOrganisationApi.middleware,
        commonApi.middleware,
        organisationsApi.middleware,
        organisationPositionsApi.middleware,
        channelsApi.middleware,
        interestsApi.middleware,
        userInterestsApi.middleware,
        channelInviteTokensApi.middleware,
        emailFilesApi.middleware,
        oauthApi.middleware,
        oauthImportApi.middleware,
        hubspotApi.middleware,
        channelImportsApi.middleware,
        salesforceApi.middleware,
        mailchimpApi.middleware,
        briefBuilderApi.middleware,
        wallpapersApi.middleware,
        userCustomPropertiesApi.middleware,
        tiptapApi.middleware
      ),
    devTools: true,
  });
}

const store = getStore();
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;

export { getStore, store };
